import React from "react";
import { useEffect, useState, useMemo } from 'react';
import { get_clients, lock_client, unlock_client } from "../../services/api";
import './index.css';
import Header from '../../components/Header';
import Clientes from '../../components/Clientes'
import ListClients from '../../components/ListClients'

import { FaLock, FaUnlock, FaDatabase } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {

    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [total, setTotal] = useState(0);

    // Hook para fazer a primeira chamada do componente
    async function loadClientes() {
        const response = await get_clients();
        setData(response.data);
        setTotal(response.data.length);
    }

    useEffect(() => {
        loadClientes();
    }, [])

    // Ver os detalhes do cliente
    function hande_details(id) {
        navigate(`/details/${id}`)
    }

    // Bloquer o cliente
    async function hande_lock(id) {
        const response = await lock_client(id);
        loadClientes();
    }

    // Desbloquer o cliente
    async function hande_unlock(id) {
        const response = await unlock_client(id);
        loadClientes();
    }


    // Colunas da nossa tabela
    const columns = useMemo(
        () => [
            {
                // Primeiro grupo - Informações do passageiro
                Header: "Informações da Empresa",
                // Colunas do primeiro grupo
                columns: [
                    {
                        Header: "Nome",
                        accessor: "name"
                    },
                    {
                        Header: "URL",
                        accessor: "url",
                        Cell: ({ cell }) => (
                            <>
                                <a href={cell.row.values.url} target="_blank" rel="noopener noreferrer">
                                    {cell.row.values.url}
                                </a>
                            </>
                        )

                    }
                ]
            },
            {
                // Segundo grupo - Detalhes do vôo
                Header: "Detalhes da Empresa",
                // Colunas do segundo grupo
                columns: [
                    {
                        Header: "Data de Subscrição",
                        accessor: "subscription_init",
                        Cell: ({ cell }) => (
                            <>
                                {cell.row.values.subscription_init === 'Invalid date' ? ('') : cell.row.values.subscription_init}
                            </>
                        )
                    },
                    {
                        Header: "Tipo de Subscrição",
                        accessor: "tipo_subscricao",
                        Cell: ({ cell }) => (
                            <>
                                {cell.row.values.tipo_subscricao === 'A' ? ('Anual') : ('Mensal')}
                            </>
                        )
                    },
                    {
                        Header: "Fim Subscrição",
                        accessor: "",
                        Cell: ({ cell }) => (
                            <>
                                {cell.row.values.subscription_init === 'Invalid date' ? ('') : cell.row.values.subscription_init}
                            </>
                        )
                    },
                    {
                        Header: "Pagamentos",
                        accessor: "",
                    },
                    {
                        Header: "Status",
                        accessor: "subscription_active",
                        Cell: ({ cell }) => (
                            <>
                                {cell.row.values.subscription_active === 1 ? <FaUnlock onClick={() => hande_lock(cell.row.values.id_client)} style={{ marginLeft: '1rem', cursor: 'pointer' }} size={18} color="#439D48" /> : <FaLock onClick={() => hande_unlock(cell.row.values.id_client)} style={{ marginLeft: '1rem', cursor: 'pointer' }} size={18} color="red" />}
                            </>
                        )
                    },
                    {
                        Header: "Ações",
                        accessor: "id_client",
                        Cell: ({ cell }) => (
                            <>
                                <FaDatabase onClick={() => hande_details(cell.row.values.id_client)} style={{ marginLeft: '1rem', cursor: 'pointer' }} size={18} color="#439D48" />
                            </>
                        )
                    }
                ]
            }
        ],
        []
    );

    return (
        <>
            <Header />
            <div className="clientes-container">
                <div className="clientes-resume">
                    <h1>Clientes</h1>
                    <h1>Total: {total}</h1>
                </div>
            </div>
            <ListClients columns={columns} data={data} />
        </>
    )
}

export default Dashboard;