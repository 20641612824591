import React, { useState, useEffect } from "react";
import { get_user } from "../../services/api";
import { useParams, Link } from 'react-router-dom';

import Header from '../../components/Header'

export default function Edit() {

    let { id } = useParams();

    const [user, setUser] = useState([]);
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await get_user(id);
            console.log(response.data)
            setUser(response.data)
            // setLoading(false)
        })();
    }, [id])

    // if (loading) {
    //     return <div className="loading">Carregando</div>
    // }

    return (
        <>

        
            <Header />

            {user.map((data) => (
                <li key={data.id} className="list-item">
                    <div className="list_info">
                        <div>
                            <img src={`https://api.spot4all.com/assets/img/${data.photo}`} alt={user.name} />
                        </div>
                        <div className="list-item-content">
                            <h4>{data.name}</h4>
                            <p>{data.email}</p>
                        </div>
                    </div>
                </li>
            ))}

            <Link to="/" >
                <button>Voltar para Home</button>
            </Link>
        </>

    )
}
