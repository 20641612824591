import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import './styles.css';

// components
import Header from "../../components/Header";
import Modulos from "../../components/Modules";
import Sms from "../../components/Sms";
import FormCompany from "../../components/Company_details_form";

// buscar dados do cliente na api
import { get_client } from '../../services/api';


const Details = () => {

    let { id } = useParams();

    const [company, setCompany] = useState([]);

    // Modulos do cliente
    const [crm, setCrm] = useState('');
    const [ic, setIc] = useState('');
    const [proc, setProc] = useState('');
    const [proj, setProj] = useState('');

    async function loadCliente(id) {
        const response = await get_client(id);
        setCrm(response.data[0].crm)
        setIc(response.data[0].ic)
        setProc(response.data[0].proc)
        setProj(response.data[0].proj)
        setCompany(response.data);
    }

    useEffect(() => {
        loadCliente(id);
    }, [id]);

    return (
        <>
            <Header />
            <div className="details-container">
                {
                    company.map((info) => (
                        <div key={info.id}>
                            <div className="details-resume">
                                <h1>Empresa: {info.name}</h1>
                                <h1>NIF: {info.nif}</h1>
                            </div>
                            <section className="details-about">
                                <h2>Detalhes</h2>
                                <br />
                                <FormCompany id={id} dados={info} />
                            </section>
                            <br />
                            <section>
                                <h2>Módulos Integrados</h2>
                                <div className="modules">
                                    <Modulos id={id} crm={crm} ic={ic} proc={proc} proj={proj} loadCliente={loadCliente} />
                                </div>
                            </section>
                            <br />
                            <section className="services">
                                <h2>Serviços Integrados</h2>
                                <br />
                                <div className="services-container">
                                    <Sms id={id} />
                                    <div className="email">
                                        criar componente de gestao de email
                                    </div>
                                </div>
                            </section>
                        </div>
                    ))
                }
            </div >
        </>
    )
}

export default Details;