import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { create_client } from '../../services/api';
import Header from '../../components/Header';
import './styles.css'

export default function NewClient() {

    const navigate = useNavigate();

    const [cname, setCname] = useState('');
    const [cemail, setCemail] = useState('');
    // const [cadress, setCadress] = useState('');
    // const [rname, setRname] = useState('');
    // const [ctel, setRtel] = useState('');
    // const [url, setUrl] = useState('');
    const [subtype, setSubtype] = useState('');
    const [modules, setModules] = useState('');

    async function handleCreateUser(e) {
        e.preventDefault();

        const dados = {
            nome_empresa: cname,
            email_empresa: cemail,
        }

        const response = await create_client(dados);
        console.log(response)
        if (response.status === true) {
            navigate('/');
        }
    }


    return (
        <>
            <Header />
            <div className="client-container">
                <h1>Dados Cliente</h1>
                <div className="">
                    <form onSubmit={handleCreateUser}>

                        <input
                            type="text"
                            placeholder="Nome da Empresa"
                            value={cname}
                            onChange={e => setCname(e.target.value)}
                        />

                        <input
                            type="email"
                            placeholder="Email da Empresa"
                            value={cemail}
                            onChange={e => setCemail(e.target.value)}
                        />

                        <div className="choises">
                            <select name='subscription_type' id='subscription_type' value={modules} onChange={e => setModules(e.target.value)}>
                                <option value="">Tipo de Subscrição</option>
                                <option value="1">Mensal</option>
                                <option value="2">Anual</option>
                            </select>
                            <select name='modules_select' id='modules_select' value={subtype} onChange={e => setSubtype(e.target.value)}>
                                <option value="">Modulos Disponiveis</option>
                                <option value="1">CRM </option>
                                <option value="2">INTERMEDIARIO DE CREDITO</option>
                                <option value="3">PROCESSUAL</option>
                                <option value="4">PROJECTOS</option>
                            </select>
                        </div>

                        <button className="button" type="submit">Criar Cliente</button>

                    </form>
                </div>
            </div>
        </>
    )
}
