import React, { useState, useEffect } from "react";
import moment from 'moment';
// buscar dados do cliente na api
import { update_client } from '../../services/api';
import { TextField, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import "./index.css"

const Swal = require('sweetalert2');

function Company_details_form({ id, dados }) {

    console.log(dados.licenses)

    const [name, setName] = useState(dados.responsable_name);
    const [cellphone, setCellphone] = useState(dados.responsable_phone);
    const [email_empresa, setEmail_empresa] = useState(dados.email);
    const [licenses, setLicences] = useState(dados.licenses);

    async function handleSubmit(e) {
        e.preventDefault();
        const data = {
            name,
            cellphone,
            email_empresa,
            licenses,
        }
        const response = await update_client(id, data);
        Swal.fire({
            title: response.data.message,
            icon: 'success',
            confirmButtonText: 'Ok'
        })
    }

    return (
        <>

            <form onSubmit={handleSubmit}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                    <Box gridColumn="span 3">
                        <TextField
                            label="Responsável"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box gridColumn="span 3">
                        <TextField
                            label="Telemóvel"
                            value={cellphone}
                            onChange={(e) => setCellphone(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box gridColumn="span 3">
                        <TextField
                            label="Email da empresa"
                            value={email_empresa}
                            type="email"
                            onChange={(e) => setEmail_empresa(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box gridColumn="span 3">
                        <TextField
                            label="Email do responsável"
                            value={dados.responsable_email}
                            // onChange={(e) => setAlphasender(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box gridColumn="span 12">
                        <TextField
                            label="Endereço"
                            value={dados.morada}
                            // onChange={(e) => setAlphasender(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box gridColumn="span 4">
                        <TextField
                            label="Licenças contratadas"
                            value={licenses}
                            onChange={(e) => setLicences(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box gridColumn="span 4">
                        <FormControl fullWidth>
                            <InputLabel>Tipo de Subscrição</InputLabel>
                            <Select
                                value={''}
                                label="Subscrição"
                            >
                                <MenuItem value={0}>Free</MenuItem>
                                <MenuItem value={1}>Mensal</MenuItem>
                                <MenuItem value={2}>Anual</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box gridColumn="span 4">
                        <TextField
                            label="Data de Início"
                            type={'date'}
                            value={moment(dados.subscription_init).format("YYYY-MM-DD")}
                            // onChange={(e) => setAlphasender(e.target.value)}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>

                <input type="submit" value='Atualizar' />
            </form>



        </>
    );
}

export default Company_details_form;