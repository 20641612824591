import React, { useState, useEffect } from "react";
import { update_module } from '../../services/api';
import "./index.css"

const Swal = require('sweetalert2');

function Modules({ id, crm, ic, proj, proc, loadCliente }) {

    // ativar ou desativar modulo crm
    async function activate_module(modulo, valor) {
        const response = await update_module(id, modulo, valor);
        loadCliente(id);
        if (response.data.status === true) {
            Swal.fire({
                title: response.data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            })
        }
    }

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setLoading(false)
        })();
    }, [])

    if (loading) {
        return <div className="loading">Carregando</div>
    }

    return (
        <>
            {crm === 0
                ? <button style={{ background: '#1d3b8c' }} onClick={() => activate_module('crm', crm)}>CRM </button>
                : <button onClick={() => activate_module('crm', crm)}>CRM</button>
            }

            {ic === 0
                ? <button style={{ background: '#1d3b8c' }} onClick={() => activate_module('ic', ic)}>DESKFIN </button>
                : <button onClick={() => activate_module('ic', ic)}>DESKFIN</button>
            }

            {proc === 0
                ? <button style={{ background: '#1d3b8c' }} onClick={() => activate_module('proc', proc)}>PROCESSUAL </button>
                : <button onClick={() => activate_module('proc', proc)}>PROCESSUAL</button>
            }

            {proj === 0
                ? <button style={{ background: '#1d3b8c' }} onClick={() => activate_module('proj', proj)}>PROJECTOS </button>
                : <button onClick={() => activate_module('proj', proj)}>PROJECTOS</button>
            }


        </>
    );
}

export default Modules;