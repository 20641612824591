import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://api.admin.spot4all.com/'
})

const token = localStorage.getItem("token");
api.defaults.headers.Authorization = 'Bearer ' + token;

export const createSession = async (email, password) => {
    return api.post('/login', {email, password})
}

// Busca todos os clientes spot4all
export const get_clients = async () => {
    return api.get('/clients');
}

// Busca um cliente especifico spot4all
export const get_client = async (id) => {
    return api.get('/client/' + id);
}

// Atualiza um cliente especifico spot4all
export const update_client = async (id, data) => {
    return api.put('/client/' + id, data);
}

// Busca um cliente especifico spot4all
export const get_account_sms = async (id) => {
    return api.get('/sms_account/' + id);
}

// criar um cliente spot4all
export const create_client = async (dados) => {
    return ({
        status: true,
        message: 'Cliente criado com sucesso'
    })
}

export const get_users = async () => {
    return api.get('/users');
}

export const get_user = async (id) => {
    return api.get('/user/' + id);
}

export const get_company = async () => {
    return api.get('/company_data');
}

export const get_modules = async () => {
    return api.get('/modules');
}

export const lock_client = async (id) => {
    return api.post('/lock_client/' + id);
}

export const unlock_client = async (id) => {
    return api.post('/unlock_client/' + id);
}

// ativar ou desativar modulo crm
export const update_module = async (id, modulo, valor) => {
    return api.put(`/module/${id}`, {modulo, valor});
}