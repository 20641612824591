import React, { useState, useEffect } from "react";
import { get_account_sms } from '../../services/api';
import { Switch, TextField, Button, Badge } from '@mui/material';
import { MdTextsms } from 'react-icons/md';
import './style.css';

export default function Sms({ id }) {

    const [balance, setBalance] = useState('');
    const [alphasender, setAlphasender] = useState("");
    const [key, setKey] = useState("");
    const [status, setStatus] = useState(false);

    async function load_sms_plan(id) {
        const response = await get_account_sms(id);
        if (response.data.status === true) {
            setStatus(response.data.status)
            setAlphasender(response.data.account[0].alpha_sender)
            setKey(response.data.account[0].licence_key)
            setBalance(response.data.balance);
        }
    }

    useEffect(() => {
        load_sms_plan(id);
    }, [id]);

    return (
        <>
            <div className="sms">
                <h2>SMS</h2>
                <div className='header'>
                    <Badge badgeContent={balance.AvailableCredits} max={9999} color="primary">
                        <MdTextsms fontSize={44} />
                    </Badge>
                    <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <TextField
                    label="Alpha Sender"
                    value={alphasender}
                    onChange={(e) => setAlphasender(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <br />
                <TextField
                    label="Chave Api"
                    type={key ? 'text' : 'password'}
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <br />
                <Button variant="contained">Gravar</Button>
            </div>
        </>
    )
}
