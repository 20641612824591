import React, { useState, useContext } from "react";
import {Link} from 'react-router-dom'
 
import { FiLogIn } from 'react-icons/fi'

import './styles.css'

import logo from '../../assets/logo_spot.png'
import rocket from '../../assets/rocket.png'

import { AuthContext } from "../../contexts/auth";

const LoginPage = () => {

    const { login, error } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('submit', { email, password })
        login(email, password)
    }

    return (
        <div className="logon-container">
            <section className="form">

                <img src={logo} id='logo' alt='logo' />

                    <form onSubmit={handleSubmit}>
                        <h1>Faça seu login</h1>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                        />
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                        />

                        {error && <p className="error">{error}</p>}

                        <button className="button" type="submit">Entrar</button>

                        <Link to="#">
                            <FiLogIn size={16} color="#FFF" />
                            Plataforma de Gestão Spot4all
                        </Link>
                    </form>

            </section>
            <img src={rocket} id="rocket" alt='logo' />
        </div>
    )
}

export default LoginPage;