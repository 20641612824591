import React, { useContext } from 'react';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from 'react-router-dom';

import LoginPage from "./pages/LoginPage"
import Details from "./pages/Details"
import Edit from "./pages/Edit"
import Dashboard from './pages/Dashboard';
import NewClient from './pages/NewClient';

import { AuthProvider, AuthContext } from './contexts/auth';

const AppRoutes = () => {

    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(AuthContext);

        if (loading) {
            return <div className='loading'>Carregando...</div>
        }

        if (!authenticated) {
            return <Navigate to="/login" />
        }

        return children;
    }

    return (
        <Router>
            <AuthProvider>
                <Routes>                    
                    <Route exact path='/login' element={<LoginPage />} />
                    <Route exact path='/' element={<Private><Dashboard /></Private>} />
                    <Route exact path='/new-client' element={<Private><NewClient /></Private>} />
                    <Route exact path='/details/:id' element={<Private><Details /></Private>} />
                    <Route path='/user/:id' element={<Private><Edit /></Private>} />
                </Routes>
            </AuthProvider>
        </Router>
    )
}

export default AppRoutes;
